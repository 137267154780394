import './App.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import logger from './logger';
import { AuthApp } from './Auth';
import { StoreProvider } from './store';

const domNode = document.getElementById('root');

if (!domNode) {
  throw new Error('No #root element');
}

const root = createRoot(domNode);

Office.onReady((info) => {
  logger.debug('office onReady', info);
  root.render(
    <StrictMode>
      <StoreProvider>
        <AuthApp />
      </StoreProvider>
    </StrictMode>,
  );
});
